<template>
  <div>
    <Toolbar stageTitle="Documents" />
    <Loader v-if="productDoc == null" />
    <div v-else class="document-wrapper">
      <div class="doc-top-navigation">
        <div class="document-toggle">
          <div
            @click="activeTab = 'legal'"
            class="document-btn"
            :class="{ ' active-btn': activeTab == 'legal' }"
          >
            Legal
          </div>
          <div
            @click="activeTab = 'product'"
            class="document-btn"
            :class="{ ' active-btn': activeTab == 'product' }"
          >
            Product Docs
          </div>
        </div>
        <div class="search-and-filter">
          <div class="search-textbox">
            <div class="div-search-btn">
              <img src="/img/doc-icons/search-icon.svg" alt="" />
            </div>
            <input
              type="text"
              placeholder="Search documents"
              class="search-text-box"
            />
          </div>
        </div>
      </div>
      <div class="document-container">
        <LegalDocuments v-if="activeTab == 'legal'" v-bind="{ grupaDoc }" />
        <ProductDocuments
          v-if="activeTab == 'product'"
          v-bind="{ productDoc }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Toolbar from "@/components/toolbar/generalToolBar";
import Loader from "@/components/general/centerLoader";
import { projectDocuments } from "@/api";
import LegalDocuments from "./legalDocuments";
import ProductDocuments from "./productDocuments";
export default {
  props: {},
  components: {
    LegalDocuments,
    ProductDocuments,
    Loader,
    Toolbar,
  },

  data: () => ({
    activeTab: "legal",
    productDoc: null,
    allDocument: [
      {
        id: 1,
        name: "Non-Disclosure Agreement",
        data: "28th April 2020",
        short: "NDA",
        document_type_id: 5,
        file_name: "Non-Disclosure Agreement.pdf",
        prekickoff: true,
      },
      {
        id: 2,
        name: "Service Level Agreement",
        data: "28th April 2020",
        short: "SLA",
        document_type_id: 5,
        file_name: "Service Level Agreement.pdf",
        prekickoff: false,
      },
      {
        id: 3,
        name: "Grupa Design Thinking Schedule",
        data: "28th April 2020",
        short: "DTS",
        document_type_id: 5,
        file_name: "Grupa Design Thinking Schedule.pdf",
        prekickoff: true,
      },
    ],
  }),

  created() {
    this.fetchDocuments();
  },
  methods: {
    fetchDocuments() {
      let productId = this.$route.params.id;
      projectDocuments(productId)
        .then((res) => {
          if (res.data.status == 1) {
            this.productDoc = res.data.media;
            this.$developConsole("All Docs", res.data);
          }
        })
        .catch((err) => this.$developConsole(err));
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProject"]),
    grupaDoc() {
      const workspace = this.clientProject.workspace_progress_level;
      if (workspace < 4) {
        return this.allDocument.filter((doc) => doc.prekickoff);
      }
      return this.allDocument;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.doc-top-navigation {
  background: #ffffff;
  padding: 12px 32px;
  display: flex;
  justify-content: space-between;
}
.document-toggle {
  background: #e4e5e7;
  border: 1px solid #e4e5e7;
  border-radius: 4px;
  display: flex;
}
.document-btn {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  cursor: pointer;
  padding: 8px 24px;
}
.active-btn {
  color: #0781f2;
  background: #fdfdfd;
  box-shadow: 2px 1px 5px rgba(27, 30, 34, 0.1);
  border-radius: 5px;
}
.search-and-filter {
  display: flex;
}
.search-textbox {
  /* height: 35px; */
  width: 290px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
}
.div-search-btn {
  padding: 9px;
}
.search-text-box {
  height: 35px;
  margin-top: 5px;
}
.search-text-box:focus {
  outline: none;
}
.document-wrapper {
  margin-top: 67px;
}
.document-container {
  padding: 32px;
  background: #f7f7f8;
  min-height: calc(100vh - 127px);
  text-align: left;
}
</style>
