var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "legal-documents" }, [
    _c("div", { staticClass: "doc-message" }, [
      _vm._v(
        "\n    You will find all documents uploaded (by you, other collaborators or the\n    product team) during product development here. You can click on the\n    document path to view them in context.\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "doc-list-container" },
      _vm._l(_vm.productDoc, function (document) {
        return _c(
          "DocumentCard",
          _vm._b(
            { key: document.id },
            "DocumentCard",
            { document: document, viewDocument: _vm.viewDocument },
            false
          )
        )
      }),
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iframe,
            expression: "iframe",
          },
        ],
        staticClass: "modal_design",
      },
      [
        _c("div", { staticClass: "modal_wrap" }, [
          _c("iframe", {
            ref: "viewDocument",
            staticClass: "cover_screen",
            staticStyle: { width: "100%", height: "100vh" },
            attrs: { id: "viewDocument", target: "_blank" },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }