<template>
  <div class="legal-documents">
    <div class="doc-message">
      You will find all documents uploaded (by you, other collaborators or the
      product team) during product development here. You can click on the
      document path to view them in context.
    </div>
    <div class="doc-list-container">
      <DocumentCard
        v-for="document in productDoc"
        :key="document.id"
        v-bind="{ document, viewDocument }"
      />
    </div>
    <div class="modal_design" v-show="iframe">
      <div class="modal_wrap">
        <iframe
          ref="viewDocument"
          id="viewDocument"
          style="width: 100%; height: 100vh"
          class="cover_screen"
          target="_blank"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentCard from "./documentCard";
export default {
  props: {
    productDoc: {
      type: Array,
      required: true,
    },
  },
  components: {
    DocumentCard,
  },
  data: () => ({
    iframe: false,
  }),

  created() {},
  methods: {
    viewDocument(document) {
      let { file_name } = document;
      let documentExtension = file_name.split(".")[1];
      this.iframe = true;
      var iframe = this.$refs.viewDocument;
      if (documentExtension == "docx" || documentExtension == "doc") {
        let urlLink = "https://docs.google.com/gview?url=";
        iframe.src = urlLink + this.$docUrl + file_name + "&embedded=true";
        var tabOrWindow = window.open(iframe.src, "_blank");
        tabOrWindow.focus();
      } else {
        iframe.src = this.$docUrl + file_name;
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.doc-message {
  width: 534px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.doc-list-container {
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
}
.modal_design {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
</style>
