<template>
  <div class="legal-documents">
    <div class="doc-message">
      You will find all mutual legal documents (generated by Grupa) here. These
      are standard documents generated to suit your product’s pecularities.
    </div>
    <div class="doc-list-container">
      <DocumentCard
        v-for="document in grupaDoc"
        :key="document.id"
        v-bind="{ document, viewDocument }"
      />
    </div>
    <GrupaNDA ref="ndaContainer" v-show="pdfText" />
    <GrupaSLA
      ref="grupa_sla"
      id="grupa-sla"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, projectDoc }"
      v-bind:devUser="false"
    />
  </div>
</template>

<script>
import * as jsPDF from "jspdf/dist/jspdf.debug";
import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import html2pdf from "html2pdf.js";
import { mapActions, mapState } from "vuex";
import GrupaNDA from "@/components/general/grupaNDA";
import GrupaSLA from "@/components/general/grupaSLA";
import DocumentCard from "./documentCard";
export default {
  props: {
    grupaDoc: {
      type: Array,
      required: true,
    },
  },
  components: {
    DocumentCard,
    GrupaSLA,
    GrupaNDA,
  },
  data: () => ({
    pdfText: false,
  }),

  created() {},
  methods: {
    viewDocument(document) {
      let { short: docName } = document;
      if (docName == "NDA") {
        let pdf = new jsPDF();
        var elementHTML = this.$refs.ndaContainer.$refs.ndaWrapper;
        var specialElementHandlers = {
          "#elementH": function (element, renderer) {
            return true;
          },
        };
        pdf.setFontSize(18);
        pdf.fromHTML(elementHTML, 15, 15, {
          width: 170,
          elementHandlers: specialElementHandlers,
        });
        pdf.output("dataurlnewwindow");
      } else if (docName == "SLA") {
        var element = this.$refs.grupa_sla.$refs.slaWrapper;
        let today = new Date();
        var date = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
        var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        var dateTime = `${date}_${time}`;
        var opt = {
          margin: [0.5, 0.5, 0.3, 0.5],
          filename: `SLA_${this.projectDoc.project.project_name}_${dateTime}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          pagebreak: { mode: ["avoid-all", "legacy"] },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        var doc = html2pdf().set(opt).from(element);
        doc.output("datauristring").then((pdfAsString) => {
          let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
          var fileURL = URL.createObjectURL(output);
          window.open(fileURL);
        });
      } else if (docName == "DTS") {
        let pdf = "/pdf/Grupa_design_sprint.pdf";
        window.open(pdf);
      } else if (docName == "GDA") {
        let pdf = "/pdf/Disciplinary_action_company_policy.pdf";
        window.open(pdf);
      }
    },
    base64ToBlob: function (base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "application/pdf" });
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProjectDoc",
      "clientProject",
      "projectTeam",
    ]),
    teamDetails() {
      if (this.clientProject.workspace_progress_level > 4) {
        let team = { ...this.projectTeam[0] };
        team.members = this.projectTeam;
        return team;
      }
      return [];
    },
    projectDoc() {
      let projectDoc = { ...this.clientProjectDoc.documents };
      projectDoc.project = this.clientProjectDoc.project;
      return projectDoc;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.doc-message {
  width: 534px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.doc-list-container {
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
}
</style>
